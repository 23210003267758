import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import About from "../components/about"
import Sponsors from "../components/sponsors"
import Memberships from "../components/memberships"
import Officers from "../components/officers"
// import Contact from "../components/contact"
import BlogPosts from "../components/blogPosts"

// location
const BlogIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  //  location = { location }
  return (
    <Layout title={siteTitle}>
      <Seo title="All posts" />

      <About />

      <Sponsors />

      <Memberships />

      <BlogPosts />

      <Officers />

      {/* <Contact /> */}
    </Layout>
  )
}
BlogIndex.propTypes = {
  data: PropTypes.object.isRequired,
  // location: PropTypes.string.isRequired,
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
