import * as React from "react"

const Officers = () => {
  return (
    <section id="contacts">
      <div className="wrapper">
        <div className="officers">
          <h3>Our Officers</h3>

          <ul>
            <li>
              <h3>
                Ginger <br />
                Spradlin
              </h3>
              <p>President</p>
            </li>
            <li>
              <h3>
                Eric <br />
                Wayland
              </h3>
              <p>Vice President</p>
            </li>
            <li>
              <h3>
                Amy <br />
                Aarons
              </h3>
              <p>Treasure</p>
            </li>
          </ul>
        </div>
        <div className="reach">
          <h3>Mail</h3>

          <address>
            P.O. Box 553 <br />
            Rutledge, TN 37861
          </address>
        </div>

        <div className="reach">
          <h3>Phone</h3>

          <p>
            <a href="tel:8657125527" title="Call us today!">
              (865) 712-5527
            </a>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Officers
