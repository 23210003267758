import * as React from "react"

const Memberships = () => {
  return (
    <section id="memberships">
      <div className="wrapper">
        <h2>Annual Memberships</h2>

        <h3>
          Join us today & help bring history to the masses for only $20/yr!
        </h3>

        <p>
          Looking for that special gift? Try the gift that gives all year with a
          membership to the Grainger County Historic Society. The recipient will
          receive a special gift card notifying them of your gift and you will
          receive a tax receipt.
        </p>

        <p>
          For more information on Membership or Donations, please contact Ginger
          Spradling, President of the Grainger County Historic Society at…
        </p>

        <p className="phone">
          <a
            href="tel:8657125527"
            title="Call Ginger Spradling for info regarding memeberships and/or donations"
          >
            (865) 712-5527
          </a>
        </p>

        <p className="note">
          <b>Note</b>: Our meetings are held every 3rd Tuesday of the month at
          6pm at the Old Jail across from Smith Funeral Home.
        </p>
      </div>
    </section>
  )
}

export default Memberships
