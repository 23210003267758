import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const BlogPosts = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: {
            fields: [childrenMarkdownRemark___frontmatter___date]
            order: DESC
          }
          limit: 3
          filter: {
            sourceInstanceName: { eq: "blog" }
            extension: { eq: "md" }
          }
        ) {
          nodes {
            childMarkdownRemark {
              id
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
              }
            }
          }
        }
      }
    `
  )
  let posts = allFile.nodes

  if (posts.length === 0) {
    return (
      <div className="stories">
        <h2>Valley Stories</h2>

        <p>
          No blog posts found. Add markdown posts to &quot;content/blog&quot;
          (or the directory you specified for the
          &quot;gatsby-source-filesystem&quot; plugin in gatsby-config.js).
        </p>
      </div>
    )
  }

  return (
    <section id="stories">
      <div className="wrapper">
        <h2>Valley Stories</h2>

        <ol className="list" style={{ listStyle: `none` }}>
          {posts.map(post => {
            post = post.childMarkdownRemark
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <p>
                      <small>{post.frontmatter.date}</small>
                    </p>
                    <h3>
                      <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h3>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                  <footer>
                    <Link className="btn" to={post.fields.slug}>
                      Read More
                    </Link>
                  </footer>
                </article>
              </li>
            )
          })}
        </ol>
      </div>
    </section>
  )
}

export default BlogPosts
