import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const About = () => {
  const sec = useStaticQuery(
    graphql`
      query {
        file(
          relativeDirectory: { eq: "about" }
          sourceInstanceName: { eq: "secs" }
        ) {
          id
          childMarkdownRemark {
            html
          }
        }
      }
    `
  )

  return (
    <section id="about">
      <div className="wrapper">
        <div
          className="msg"
          dangerouslySetInnerHTML={{
            __html: sec.file.childMarkdownRemark.html,
          }}
        />
        <div className="vid">
          <iframe
            width="680"
            height="414"
            src="https://www.youtube.com/embed/pVMSAnfK3yI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  )
}

export default About
