import * as React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
// import styled from "styled-components"

// const Container = styled.div`
//   margin: 3rem auto;
//   max-width: 600px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// `

// const Sponsor = (to, title, img) => (
//   <li>
//     <a href={to} title={"Visit, " + title} target="_blank" rel="noreferrer">
//       <GatsbyImage
//         src={"../images/sponsors/" + img + ".jpg"}
//         alt={title}
//         // placeholder="blurred"
//         layout="constrained"
//       />
//       <figcaption>{title}</figcaption>
//     </a>
//   </li>
// )

const Sponsors = () => {
  return (
    <section id="sponsors">
      <div className="wrapper">
        <div className="msg">
          <h2>Support Our Sponsors</h2>
        </div>

        <div className="list">
          <ul>
            {/* <Sponsor
              title="Appalachian Electric Cooperative"
              to="https://aecoop.org"
              img="aec"
            /> */}

            {/* <li>
              <a
                href
                title="Visit Appalachian Electric Cooperative"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/aec.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Appalachian Electric Cooperative</figcaption>
              </a>
            </li> */}

            <li>
              <a
                href="https://www.agc.org"
                title="Visit A.G.C. of America"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/agc.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>A.G.C. of America</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://bridgesfuneralhome.com"
                title="Visit Bridges Funeral Home"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/bridges.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Bridges Funeral Home</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://meritconstruction.com"
                title="Visit CEO Marv House - Merit Construction"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/merit.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>CEO Marv House - Merit Construction</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://www.cbtgrainger.com"
                title="Visit Citizens Bank and Trust"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/citizens.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Citizens Bank and Trust</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://en.wikipedia.org/wiki/Bean_Station,_Tennessee"
                title="Visit City of Bean Station"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/beanstation.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>City of Bean Station</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://en.wikipedia.org/wiki/Blaine,_Tennessee"
                title="Visit City of Blaine"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/blaine.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>City of Blaine</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://www.claytonhomes.com"
                title="Visit Clayton Homes"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/clayton.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Clayton Homes</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://www.daltonhydraulic.com"
                title="Visit Dalton Hydraulics"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/dalton.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Dalton Hydraulics</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/Down-Home-Restaurant-111889023899418/"
                title="Visit Down Home Restaurant"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/downhome.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Down Home Restaurant</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://frontier.com"
                title="Visit Frontier Communications"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/frontier.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Frontier Communications</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://www.grangermotors.com"
                title="Visit Grainger Automotive LLC"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/graingerauto.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Grainger Automotive LLC</figcaption>
              </a>
            </li>
            <li>
              <a
                href="http://graingercochamber.org"
                title="Visit Grainger Chamber of Commerce"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/chambercommerce.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Grainger Chamber of Commerce</figcaption>
              </a>
            </li>
            <li>
              <a
                href="http://www.graingercountytn.com"
                title="Visit Grainger County, TN"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/graingerco.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Grainger County, TN</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://www.graingertoday.com"
                title="Visit Grainger Today"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/graingertoday.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Grainger Today</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://holtsfoodcenter.iga.com"
                title="Visit Holt's IGA Market"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/iga.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Holt&apos;s IGA Market</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://www.jfmechanical.com"
                title="Visit J & F Mechanical Inc"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/jfm.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>J & F Mechanical Inc</figcaption>
              </a>
            </li>
            <li>
              <span>Lyle Finley</span>
            </li>
            <li>
              <a
                href="https://norrishomes.com"
                title="Visit Norris Homes"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/norris.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Norris Homes</figcaption>
              </a>
            </li>
            <li>
              <a
                href="http://www.pc2-inc.com"
                title="Visit Professional Construction Consulting"
                target="_blank"
                rel="noreferrer"
              >
                <span>Professional Construction Consulting</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.ritterfarms.com"
                title="Visit Ritter Farms"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/ritter.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Ritter Farms</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/sextonfurnituremfg/"
                title="Visit Sexton Furniture Manufacturing"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/sexton.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>Sexton Furniture Manufacturing</figcaption>
              </a>
            </li>
            <li>
              <a
                href="https://www.tva.com"
                title="Visit TVA, Tennessee Valley Authority"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/sponsors/tva.jpg"
                  alt="A dinosaur"
                  // placeholder="blurred"
                  layout="constrained"
                  quality="100"
                />
                <figcaption>TVA, Tennessee Valley Authority</figcaption>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Sponsors
